'use client';

import {
  signInWithEmailAndPassword,
  User,
  NextOrObserver,
  OAuthProvider,
  signInWithRedirect,
  signOut as _signOut,
  onAuthStateChanged as _onAuthStateChanged,
} from 'firebase/auth';

import type { CreateRequest } from 'firebase-admin/auth';
import type { Provider } from '@/lib/firebase/types';
import { auth } from '@/lib/firebase/utils/clientApp';
import { INITIAL_USER_DATA } from '@/lib/firebase/constants';
import createUser from '@/lib/firebase/auth/createUser';
import _createOTP from '@/lib/firebase/auth/createOTP';
import _verifyOTP from '@/lib/firebase/auth/verifyOTP';

export async function signInWithProvider(name: Provider) {
  const provider = new OAuthProvider(name);
  // provider.addScope('openid');

  const result = await signInWithRedirect(auth, provider);

  return result;
}

export function signInWithEmail(email: string, password: string) {
  return signInWithEmailAndPassword(auth, email, password);
}

export async function signUpWithEmail<T>(
  data: {
    userData?: Partial<T>;
    user: CreateRequest & {
      email: string;
      password: string;
    };
  },
  login?: boolean,
) {
  return createUser({
    userData: { ...INITIAL_USER_DATA, ...data.userData },
    user: data.user,
  }).then(() => (login ? signInWithEmail(data.user.email, data.user.password) : undefined));
}

export function signOut() {
  return _signOut(auth);
}

export function onAuthStateChanged(cb: NextOrObserver<User>) {
  return _onAuthStateChanged(auth, cb);
}

export async function verifyOTP(email: string, code: string) {
  return _verifyOTP(email, code);
}

export async function createOTP(email: string) {
  return _createOTP(email);
}
