'use client';

import { create } from 'zustand';
import type { UpdateRequest } from 'firebase-admin/auth';
import type { BaseDocument } from '@/lib/firebase/types';
import type { Role, UserRecord } from '@/types/schemas';
import getUser from '@/lib/firebase/auth/getUser';

const initialState = {
  userId: null,
  userData: null,
  loaded: false,
  role: null,
};

const createAuthStore = <T>() => {
  type UserData = T & UpdateRequest & BaseDocument & UserRecord;

  interface AuthState {
    userId: string | null;
    userData: Partial<UserData> | null;
    loaded: boolean;
    setUserData: (userData: Partial<UserData> | null) => void;
    getUserData: () => Promise<Partial<UserData> | null>;
    reset: () => void;
    role: Role | null;
  }

  return create<AuthState>((set, get) => ({
    ...initialState,
    setUserData: (userData: Partial<UserData> | null) => {
      set({
        userData,
        loaded: true,
        userId: userData?.uid || null,
        role: userData?.role || null,
      });
    },
    getUserData: async () => {
      const { userId } = get();
      const userData = await getUser<UserData>({ uid: userId! });

      set({
        userData,
        loaded: true,
        userId: userData?.uid || null,
        role: userData?.role || null,
      });

      return userData;
    },
    reset: () => {
      set(initialState);
    },
  }));
};

export default createAuthStore;
