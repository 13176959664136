'use client';

import { useEffect } from 'react';
import { useNotificationStore, NotificationType } from '@/lib/stores/notificationStore';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function Notifications() {
  const { notifications, removeNotification } = useNotificationStore();

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <Stack spacing={2} sx={{ position: 'fixed', bottom: 24, right: 24, zIndex: 2000 }}>
      {notifications.map((notification) => (
        <Snackbar
          key={notification.id}
          open={true}
          autoHideDuration={notification.autoHideDuration || 5000}
          onClose={() => removeNotification(notification.id)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          sx={{ position: 'static', mb: 1 }}
        >
          <Alert
            onClose={() => removeNotification(notification.id)}
            severity={notification.type}
            variant='filled'
            sx={{ width: '100%' }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      ))}
    </Stack>
  );
}

export function showNotification(
  message: string,
  type: NotificationType = 'info',
  autoHideDuration = 5000,
) {
  return useNotificationStore.getState().addNotification({
    message,
    type,
    autoHideDuration,
  });
}
